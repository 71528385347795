import React from "react"
import {graphql} from 'gatsby'

import Image from "../components/image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from "../components/headerSection"
import BlockContent from "../components/block-content"

export const query = graphql`
query {
  sanityCollaborate {
    image {
      asset {
        fluid(maxWidth: 1280) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    _rawText
    title
  }
}
`

const Contact = ({data}) => {
  const {image, _rawText, title} = data.sanityCollaborate

  return(
    <Layout>
      <SEO title="Contact"/>
      <Image className="contact-image"fluid={image.asset.fluid} alt="Experience Ubuntu Collaborate, Contact"/>
      <div className="contact-header">
        <HeaderSection title="Collaborate"/>
      </div>
      <div className="contact--text-container">
        <h3 data-sal="slide-down" data-sal-delay="150" data-sal-duration="800" data-sal-easing="ease">{title}</h3>
        <BlockContent blocks={_rawText || []}/>
      </div>
      <form className="form--container grid-container-13" name="contact-ubuntu" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field"/>
        <input type="hidden" name="form-name" value="contact-ubuntu"/>

        <div className="inputs">
          <input type="text"  name="full-name" placeholder="Full Name*" required/>
          <input type="email" name="email"  placeholder="Email*" required/>
          <input type="text"  name="company-name" placeholder="Company Name"/>
          <input type="text"  name="industry" placeholder="Industry"/>
          <input type="text"  name="mission" placeholder="Mission"/>
        </div>
        <div className="inputs-right">
          <p>Tell us more. What do you do? What do you stand for?
          <br/>What do you hope to accomplish from a mutual collaboration? </p>
          <textarea name="more-info" id="text-area" cols="60" rows="14"></textarea>
          <button>SEND</button>
        </div>
      </form>
    </Layout>
  )
}

export default Contact
