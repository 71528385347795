import React from "react"

const HeaderSection = ({title, description, author, maxWidth}) => (
  <div className="section--header">
    <h2 className="heading" data-sal="slide-up" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">{title}</h2>
    <h3 style={{width: `${maxWidth}`}} data-sal="fade" data-sal-delay="250" data-sal-duration="800" data-sal-easing="ease">{description}</h3>
    {!!author && (
      <p>- {author} -</p>
    )}
  </div>
)

export default HeaderSection
